/**
 * @license
 * @copyright Copyright Motili Inc., 2024 All Rights Reserved
 */

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import contractor from 'admin/stores/redux/ducks/AdminContractorRegistration';
import recentlyViewed from 'admin/stores/redux/AdminRecentlyViewedStore';
import workOrder from 'admin/stores/redux/ducks/AdminWorkOrderCreation';
import recentlyViewedJobs from 'contractor/stores/ContractorRecentlyViewedJobsStore';
import adminFinanceInvoicesListFilter, {
    actions as adminFinanceInvoicesListFilterActions,
} from 'admin/stores/redux/AdminFinanceInvoicesListFilter';
import adminFinanceInvoiceCreateFilter from 'admin/stores/redux/AdminFinanceInvoiceCreateFilter';
import adminFinanceSyncTaskListFilter from 'admin/stores/redux/AdminFinanceSyncTaskListFilter';
import requirements from 'common/stores/RequirementsSpecsStore';
import distributionCentersAndInventory from 'common/stores/redux/DistributionCentersAndInventory/DistributionCentersAndInventory';
import dataGridState from './DataGrid/DataGrid';

import QueryStoreApi from './baseApi';
import metaStore from './MetaStore/MetaStore';
import businessChannel from '../../../admin/stores/redux/AdminBusinessChannelStore';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: [
        'contractor',
        'workOrder',
        'requirements',
        'recentlyViewedJobs',
    ],
};

const rootReducer = combineReducers({
    contractor,
    recentlyViewed,
    workOrder,
    metaStore,
    dataGridState,
    [QueryStoreApi.reducerPath]: QueryStoreApi.reducer,
    requirements,
    businessChannel,
    recentlyViewedJobs,
    adminFinanceInvoicesListFilter,
    adminFinanceInvoiceCreateFilter,
    adminFinanceSyncTaskListFilter,
    distributionCentersAndInventory,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(QueryStoreApi.middleware),
});

export const initStoreAfterLogin = () => {
    store.dispatch(adminFinanceInvoicesListFilterActions.init());
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
