import { createSlice } from '@reduxjs/toolkit';

const defaultFilters: any = {
    initialized: false,
    searchFilters: [],
    states: [],
    selectedStates: [],
    distinctVendors: [],
    selectedDistinctVendors: [],
    regions: [],
    zipcode: '',
    showInventory: false,
    distributionCenterForInventory: {},
    inventoryResults: [],
    searchType: {},
    searchText: '',
};

export const distributionCentersAndInventorySlice = createSlice({
    name: 'distributionCentersAndInventory',
    initialState: defaultFilters,
    reducers: {
        resetFilters: () => {
            return defaultFilters;
        },
        resetAdvancedFilters: state => {
            return {
                ...state,
                selectedStates: [],
                selectedDistinctVendors: [],
                regions: [],
                zipcode: '',
                showInventory: false,
            };
        },
        resetSearchFilters: state => {
            return {
                ...state,
                searchFilters: [],
            };
        },
        initializeFilters: (state, action) => {
            return {
                ...state,
                ...action.payload,
                distinctVendors: action.payload.distinctVendors,
                states: action.payload.states,
                initialized: true,
            };
        },
        setSearchFilters: (state, action) => {
            return {
                ...state,
                searchFilters: action.payload,
            };
        },
        setSelectedStates: (state, action) => {
            return {
                ...state,
                selectedStates: action.payload,
            };
        },
        setSelectedDistinctVendors: (state, action) => {
            return {
                ...state,
                selectedDistinctVendors: action.payload,
            };
        },
        setRegions: (state, action) => {
            return {
                ...state,
                regions: action.payload,
            };
        },
        setZipcode: (state, action) => {
            if (action.payload.length === 0) {
                return {
                    ...state,
                    zipcode: '',
                };
            }
            return {
                ...state,
                zipcode: action.payload,
            };
        },
        setShowInventory: (state, action) => {
            return {
                ...state,
                showInventory: action.payload,
            };
        },
        setDistributionCenterForInventory: (state, action) => {
            return {
                ...state,
                distributionCenterForInventory: action.payload,
            };
        },
        setInventoryResults: (state, action) => {
            return {
                ...state,
                inventoryResults: action.payload,
            };
        },
        setSearchType: (state, action) => {
            return {
                ...state,
                searchType: action.payload,
            };
        },
        setSearchText: (state, action) => {
            return {
                ...state,
                searchText: action.payload,
            };
        },
    },
});

export const {
    resetFilters,
    resetAdvancedFilters,
    resetSearchFilters,
    initializeFilters,
    setSearchFilters,
    setSelectedStates,
    setSelectedDistinctVendors,
    setRegions,
    setZipcode,
    setShowInventory,
    setDistributionCenterForInventory,
    setInventoryResults,
    setSearchText,
    setSearchType,
} = distributionCentersAndInventorySlice.actions;

export default distributionCentersAndInventorySlice.reducer;
